/*********** Color Contrast Issues ******************/
/***************************************************/
/* /core/libraries/jquery.mmenu/assets/6.1.3/jquery.mmenu.all.min.css */
.mm-menu.mm-theme-dark .mm-search input {
	background: #000000;
	color: #ffffff;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:focus,
.fly-out-menu-list-container ul.fly-out-menu-list li a:hover {
	background: #175797;
	color: #ffffff;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #666666;
	color: #ffffff;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	color: #666666;
}

/* /core/website/modules/eCommerceProducts/css/responsive.css */
.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li.selected a {
	background: #175797 !important;
	color: #ffffff !important;
}

/* core/modules/ecommerce/assets/css/all.css */
.payment-steps-bar .payment-step .payment-step-item {
	color: #ffffff;
	background: #595959;
}

.payment-steps-bar .payment-step .payment-step-item .payment-step-item-number {
	color: #d8d8d8;
	background: #595959;
}

/* /core/modules/ecommerce/assets/css/responsive.css */
#s-checkout #s-steps-bar-small ul li .s-step {
	background: #565459;
	color: #ffffff;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:focus,
.fly-out-menu-list-container ul.fly-out-menu-list li a:hover {
	background: #154f8a;
	color: #ffffff;
}

/* core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #666666;
	color: #ffffff;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #f2f2f2;
	color: #4d4d4d;
}


/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #125a91;
	background-color: #fff;
}

/* /core/modules/files/assets/stylesheets/all.css */
.controller-manage-categories form fieldset .legend {
	background: #464444;
	color: #ffffff;
}

/* /core/website/modules/events/css/screen.css*/
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	background-color: #3b3b3b;
	color: #000000;
}



/*********** Relative Unit Values ******************/
/***************************************************/
/* /core/libraries/jquery.mmenu/assets/6.1.3/jquery.mmenu.all.min.css */
.mm-listview {
	font-size: 0.875rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-note-extra {
	font-size: 0.68rem;
}

.ui-form-legend {
	font-size: 1rem;
}

/****************** Focus Outline ******************/
/***************************************************/
/* /core/libraries/slickSlider/assets/1.4.1/slick.css */
.slick-list:focus {
	outline: 1px dotted #cccccc;
}
